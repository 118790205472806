import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { Language } from './projects.model';

export interface BrandProject {
  project_id: number;
  north_star: string;
  title: Language;
  short_description: Language;
  slug: string;
  picture_thumbnail: string;
  organization: string;
  read_more_clicked: boolean;
}

export interface ValidateImpactRequest {
  email: string;
  customers_pledge_id: number;
  project_id: number;
  language: string;
}

export interface ValidateMatchingGiftRequest {
  email: string;
  amount: number;
  matching_gift_id: string;
  project_id: string;
  card_number: string;
  expiry_month: string;
  expiry_year: string;
  cvc: string;
  language: string;
}

export enum ValidateImpactResponses {
  Success,
  Premium,
  Error,
  AlreadyVoted,
  TooLate
}

export enum ValidateMatchingGiftResponses {
  Success,
  Premium,
  Error,
}

export interface BrandImpactResponse {
  current_program: CurrentProgram;
  impact: Impact;
  programs: Programs;
  company_info: CompanyInfo;
  no_kpi_project: NoKpiProject;
  company_cards: CompanyCard[];
}

export interface CurrentProgram {
  id: number;
  type: string;
  title: Language;
  slug: Language;
  end_date: string;
  text: Language;
  picture_thumbnail: string;
}

export interface Impact {
  all: ImpactObject;
  employees: ImpactObject;
  customers: ImpactObject;
  matching_gift: ImpactObject;
}

export interface Programs {
  all: ProgramObject[];
  employees: ProgramObject[];
  customers: ProgramObject[];
  matching_gift: ProgramObject[];
}

export interface CompanyInfo {
  name: string;
  slug: string;
  logo_url: string;
  display_logo: boolean;
  bg_image_url: string;
  social_media_links?: SocialMediaLink[];
  description: string;
  external_impact: string;
  media: string[];
  company_page_title: Language;
  picture_quote: Language;
  blur_image: boolean;
}

export interface NoKpiProject {
  additional_data_bottom: Language;
  additional_data_middle: string;
  additional_data_top: Language;
  id: number;
  name: Language;
  slug: string;
}

export interface CompanyCard {
  content: Language;
  cta_title: Language;
  cta_url: string;
  footer: Language;
  template: string;
  title: Language;
}

export interface SocialMediaLink {
  icon_url: string;
  link: string;
}

export interface ImpactObject {
  causes: Causes;
  countries: {};
  projects: ProjectObject[];
}

export interface Cause {
  donated: number;
  quantity: number;
  percentage: number;
}

export interface Causes {
  biodiversity: Cause;
  education: Cause;
  health: Cause;
  zero_waste: Cause;
  reforestation: Cause;
  inclusiveness: Cause;
  carbon_offset: Cause;
  diverse_actions: Cause;
}

export interface ProgramObject {
  type: string;
  id: number;
  name: Language;
  amount: number;
  end_date: string;
}

export interface BrandProgramResponse {
  customers_pledge_id: number;
  slug: Language;
  company_name: string;
  company_slug: string;
  amount: number;
  start_date: string;
  end_date: string;
  picture_cover: string;
  title: Language;
  subtitle: Language;
  description: Language;
  color: string;
  logo: string;
  projects: BrandProject[];
  premium_number: number;
  premium_factor: number;
  hide_button: boolean;
  clickable_images: boolean;
}

export interface MatchingGiftResponse {
  matching_gift_id: number;
  slug: Language;
  company_name: string;
  company_slug: string;
  multiplication_factor: number;
  premium_number: number;
  premium_factor: number;
  max_amount: number;
  donated_amount: number;
  start_date: string;
  end_date: string;
  picture_cover: string;
  picture_thumbnail: string;
  title: string;
  subtitle: string;
  description: string;
  color: string;
  logo: string;
  projects: BrandProject[];
}

export interface ProjectObject {
  id: number;
  name: Language;
  slug: Language;
  progress: number;
  north_star: string;
  picture_thumbnail: string;
  text: string;
}

export interface SliderItem {
  name: string;
  quantity: number;
}

export class DataForImpact {
  biodiversity: number;
  education: number;
  health: number;
  zeroWaste: number;
  reforestation: number;
  inclusiveness: number;
  carbonOffset: number;
  diverseActions: number;

  static parseDataForImpact(data: BrandImpactResponse): DataForImpact {
    return {
      biodiversity: data.impact.all.causes.biodiversity.quantity,
      education: data.impact.all.causes.education.quantity,
      health: data.impact.all.causes.health.quantity,
      zeroWaste: data.impact.all.causes.zero_waste.quantity,
      reforestation: data.impact.all.causes.reforestation.quantity,
      inclusiveness: data.impact.all.causes.inclusiveness.quantity,
      carbonOffset: data.impact.all.causes.carbon_offset.quantity,
      diverseActions: data.impact.all.causes.diverse_actions.quantity
    };
  }
}

interface ChartData {
  series: { data: number[] }[];
  labels: string[];
}

export class DataForChart {
  all: ChartData;

  static parseDataForChart(data: BrandImpactResponse): DataForChart {
    const getLabelsAndSeries = (causes: Causes) => {
      // the order of the causes is very important so that the chart is accurate
      const causesEntries = ['carbon_offset', 'reforestation', 'education', 'biodiversity', 'health', 'zero_waste', 'inclusiveness', 'diverse_actions'];
      const series = new Array(7);
      const labels = new Array(7);
      causesEntries.forEach((key, index) => {
        labels[index] = (new CapitalizePipe()).transform(key);
        series[index] = causes[key]['percentage'];
      });

      return { series, labels };
    };

    const allData = getLabelsAndSeries(data.impact.all.causes);

    const all = { series: [{ data: allData.series }], labels: allData.labels };
    return { all };
  }
}

export class DataForMapChart {
  all: [string, number][];

  static parseDataForMapChart(data: BrandImpactResponse): DataForMapChart {
    const getMapObjects = (countries: object) => {
      const countriesEntries = Object.entries(countries).filter(item => item[1].percentage > 0);
      const countriesArray = new Array(countriesEntries.length);
      countriesEntries.forEach((item, index) => {
        countriesArray[index] = [item[0], item[1].percentage, item[1].coordinates];
      });
      return countriesArray;
    };
    const all = getMapObjects(data.impact.all.countries);
    return { all };
  }
}

export class DataForProjects {
  all: ProjectObject[];
  employees: ProjectObject[];
  customers: ProjectObject[];
  matchingGift: ProjectObject[];

  static parseDataForProjects(data: BrandImpactResponse): DataForProjects {
    const all = data.impact.all.projects;
    const employees = data.impact.employees.projects;
    const customers = data.impact.customers.projects;
    const matchingGift = data.impact.matching_gift.projects;
    return { all, employees, customers, matchingGift };
  }
}

export class DataForPrograms {
  all: ProgramObject[];
  employees: ProgramObject[];
  customers: ProgramObject[];
  matchingGift: ProgramObject[];

  static parseDataForPrograms(data: BrandImpactResponse): DataForPrograms {
    const all = data.programs.all;
    const employees = data.programs.employees;
    const customers = data.programs.customers;
    const matchingGift = data.programs.matching_gift;
    return { all, employees, customers, matchingGift };
  }
}

export class DataForCurrentProgram {
  static parseDataForProgram(data: BrandImpactResponse): CurrentProgram {
    return data.current_program;
  }
}

export class DataForCompanyInfo {
  static parseDataForCompany(data: BrandImpactResponse): CompanyInfo {
    return data.company_info;
  }
}

export class DataForNoKpiProject {
  static parseDataForNoKpiProject(data: BrandImpactResponse): NoKpiProject {
    if (data.no_kpi_project) {
      return data.no_kpi_project;
    } else {
      return null;
    }
  }
}

export class DataForCompanyCards {
  static parseDataForCompanyCards(data: BrandImpactResponse): CompanyCard[] {
    if (data.company_cards) {
      return data.company_cards;
    } else {
      return null;
    }
  }
}

export class DataForImpactPage {
  impact: DataForImpact;
  chart: DataForChart;
  chartMap: DataForMapChart;
  projects: DataForProjects;
  programs: DataForPrograms;
  currentProgram: CurrentProgram;
  companyInfo: CompanyInfo;
  noKpiProject: NoKpiProject;
  companyCards: CompanyCard[];

  constructor(data: BrandImpactResponse) {
    return {
      impact: DataForImpact.parseDataForImpact(data),
      chart: DataForChart.parseDataForChart(data),
      chartMap: DataForMapChart.parseDataForMapChart(data),
      projects: DataForProjects.parseDataForProjects(data),
      programs: DataForPrograms.parseDataForPrograms(data),
      currentProgram: DataForCurrentProgram.parseDataForProgram(data),
      companyInfo: DataForCompanyInfo.parseDataForCompany(data),
      noKpiProject: DataForNoKpiProject.parseDataForNoKpiProject(data),
      companyCards: DataForCompanyCards.parseDataForCompanyCards(data)
    };
  }
}


export class DataForBrandsPage {
  companies: Brands[];
  impact: ImpactData;
}

export class Brands {
  name: string;
  slug: string;
  logo: string;
  picture: string;
}

export class ImpactData {
  education: number;
  biodiversity: number;
  reforestation: number;
  health: number;
  zero_waste: number;
  inclusiveness: number;
  carbon_offset: number;
  diverse_actions: number;
}

export interface BrandAccessibility {
  slug: string;
  alias: string[];
  accessible: boolean;
  brand_id: number;
  pledge_ids: number[];
}
